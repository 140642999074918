import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import config from '../utils/siteConfig'
import styled, { css } from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import posed, { PoseGroup } from 'react-pose'
import Alert from '@reach/alert'
import {
  FaPhone,
  FaEnvelope,
  FaThumbsUp,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from 'react-icons/fa'

import { media } from '../utils/media'
import theme from '../styles/theme'

import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'

import HeroNobackground from '../components/HeroNoBackground'
import { LocationsSection } from '../components/Section'
import Media from 'react-media';
import { breakpoints, mediaQueries, space, fontSizes, colors } from '../utils/tokens'
import { MainLayout } from '../components/MainLayout'

const SlideUp = posed.div({
  enter: {
    y: `-100%`,
    x: `-50%`,
    opacity: 1,
  },
  exit: {
    y: 0,
    x: `-50%`,
    opacity: 0,
  },
})

const Heading = styled.h3`
  color: ${props => props.theme.colors.white}d4;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
`

const UnstyledAnchor = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  font-size: 1.25rem;
  font-weight: 500;
  z-index: 1;

  opacity: 0.8;
  transition-duration: 150ms;
  
  :hover {
    opacity: 1;
  }
`

const HeadingWithIconContainer = styled.div`
  display: flex;
  align-items: center; 
  margin: 0;
  justify-content: center;

  ${media.desktop`
    align-items: flex-start;
  `}
`

const HeadingWithIcon = props => (
  <HeadingWithIconContainer>
    <Heading>{props.heading}</Heading>
    <span
      className="icon"
      css={css`
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(50%);
        opacity: 0.5;
        transition-duration: 300ms;
        > * {
          height: 75%;
          width: auto;
          color: ${props => props.theme.colors.primaryBrand};
        }
      `}
    >
      {props.icon}
    </span>
  </HeadingWithIconContainer>
)

const ContactCard = styled.div`
  background-color: ${props => props.theme.colors.base};
  color: ${props => props.theme.colors.white};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  padding: 2rem 0;

  :hover {
    .icon {
      opacity: 1;
      transform: translateX(0) rotate(-15deg);
    }
  }
`

const ContactCardAnchor = styled.a`
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.base};
  color: ${props => props.theme.colors.white};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  padding: 2rem 0;

  :hover {
    .icon {
      opacity: 1;
      transform: translateX(0) rotate(-15deg);
    }
  }
`
const Contact = ({ data }) => {
  const { hero, title, slug } = data.contentfulPage
  const postNode = data.contentfulPage

  const [copied, setCopied] = useState(false)
  const [mediaKey, setMediaKey] = useState(0)

  useEffect(() => {
    setMediaKey(1)
  })

  const handleAlert = () => {
    setCopied(true)

    setTimeout(() => setCopied(false), 3000)
  }

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <MainLayout>
        <Helmet>
          <title>{`${title} - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postNode={postNode} pagePath={slug} customTitle pageSEO />
        <section
          css={css({
            paddingTop: `${space[5]}px`,
          })}
        >
          <HeroNobackground hero={hero} />
        </section>

        <section
          css={css`
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
            > * {
              margin-bottom: 1rem;
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            ${media.desktop`
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              grid-gap: 8px 2rem;
              padding: 0;

              max-width: ${props => props.theme.sizes.maxWidth};
              margin: auto;

              > * {
                margin-bottom: 0;
              }
            `}
          `}
        >
          <ContactCardAnchor
            css={css`
              cursor: pointer;
            `}
            href="tel:+1-929-213-9604"
          >
            <HeadingWithIcon
              icon={<FaPhone />}
              alt="telephone"
              heading="Phone number"
            />
            <span
              css={css`
                color: ${props => props.theme.colors.white};
                font-size: 1.25rem;
                font-weight: 500;
                z-index: 1;
              `}
            >
              + 1(929) 213 -9604
            </span>
          </ContactCardAnchor>
          <Media query="(max-width: 550px)" key={mediaKey}>
            {matches =>
              matches ? (
                <ContactCardAnchor
                  css={css`
                    cursor: pointer;
                  `}
                  href="mailto:contact@prtwd.com"
                >
                  <HeadingWithIcon
                    icon={<FaEnvelope />}
                    alt="mail"
                    heading="Email"
                  />
                  <span
                    css={css`
                      color: ${props => props.theme.colors.white};
                      font-size: 1.25rem;
                      font-weight: 500;
                      z-index: 1;
                    `}
                  >
                    contact@prtwd.com
                  </span>
                </ContactCardAnchor>
              ) : (
                <CopyToClipboard text="contact@prtwd.com" onCopy={handleAlert}>
                  <ContactCardAnchor
                    css={css`
                      cursor: pointer;
                    `}
                    onClick={handleAlert}
                  >
                    <HeadingWithIcon
                      icon={<FaEnvelope />}
                      alt="mail"
                      heading="Email"
                    />
                    <span
                      css={css`
                        color: ${props => props.theme.colors.white};
                        font-size: 1.25rem;
                        font-weight: 500;
                        z-index: 1;
                        user-select: all;
                      `}
                    >
                      contact@prtwd.com
                    </span>
                  </ContactCardAnchor>
                </CopyToClipboard>
              )
            }
          </Media>
          <ContactCard>
            <HeadingWithIcon
              icon={<FaThumbsUp />}
              alt="thumbs up"
              heading="Social Media"
            />
            <div
              css={css`
                display: flex;
                width: 100%;
                justify-content: space-around;

                > * {
                  margin-right: 1rem;
                  font-size: 1.5rem;
                }

                ${media.desktop`
                    justify-content: center;
                  `}
              `}
            >
              <UnstyledAnchor
                href="https://instagram.com/printaworld3d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </UnstyledAnchor>
              <UnstyledAnchor
                href="https://www.facebook.com/printaworld"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </UnstyledAnchor>
              <UnstyledAnchor
                href="https://www.twitter.com/PrintAworld3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </UnstyledAnchor>
              <UnstyledAnchor
                href="https://www.linkedin.com/company/printaworld"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </UnstyledAnchor>
            </div>
          </ContactCard>
        </section>
        <Media query="(max-width: 550px)" key={mediaKey}>
          {matches =>
            !matches ? (
              <Alert
                css={css`
                  ${media.desktop`
                    position: absolute;
                    left: 50%;
                    right: 50%;
                    width: 100%;
                  `}
                `}
              >
                <PoseGroup flipMove={false}>
                  {copied && (
                    <SlideUp
                      key="slide-up"
                      css={css`
                        position: absolute;
                        bottom: -4rem;
                        left: auto;
                        right: auto;
                        transform: translateX(-50%);
                        z-index: 30;
                      `}
                    >
                      <div
                        css={css`
                          background-color: #d4edda;
                          color: ${theme.colors.base};
                          border-radius: 4px;
                          text-align: center;
                          padding: 8px 16px;
                        `}
                      >
                        <span>Email copied to clipboard!</span>
                      </div>
                    </SlideUp>
                  )}
                </PoseGroup>
              </Alert>
            ) : null
          }
        </Media>
        <section
          css={css({
            maxWidth: breakpoints.xl,
            padding: `${space[4]}px ${space[3]}px`,
            margin: `0 auto`,
            [mediaQueries.lg]: {
              padding: `${space[5]}px 0`,
            },
          })}
        >
          <h3
            css={css({
              fontWeight: `bold`,
              fontSize: fontSizes[4],
              marginBottom: `${space[4]}px`,
            })}
          >
            Talk to us
          </h3>
          <ContactForm />
        </section>
        <LocationsSection
          css={css`
            position: relative;
            grid-column: span 3;
            padding: ${space[5]}px 0;
            background-color: ${colors.base};

            ${media.desktop`
              padding: ${space[6]}px 0 ${space[5]}px 0;
            `}
          `}
        />
      </MainLayout>
    </div>
  )
}

export default Contact

export const contactPageQuery = graphql`
  query ContactPageQuery {
    contentfulPage(slug: { eq: "contact" }) {
      hero { 
        ...HeroFragment_withNoBackground
      }
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
    }
  }
`
