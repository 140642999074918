import React, { useState } from 'react'
import { css } from 'styled-components'
import { DialogOverlay, DialogContent } from '@reach/dialog'

import Button from '../components/Button'

import { space, breakpoints, colors, fontSizes, mediaQueries, zIndices } from '../utils/tokens'
import { button } from '../utils/styles'

const labelStyle = {
  display: `flex`,
  flexDirection: `column`,
}

const labelSpanStyle = {
  marginBottom: `${space[1]}px`,
  fontSize: fontSizes[2],
}

const formGroupStyle = {
  marginBottom: `${space[4]}px`,
}

const inputStyle = {
  caretColor: colors.base,
  borderColor: colors.gray,
  fontSize: fontSizes[3],
  borderWidth: `1px`,
  borderStyle: `solid`,
  borderRadius: `2px`,
  padding: `${space[2]}px`,
  outline: `none`,
  ':focus': {
    boxShadow: `${colors.base}a1 0px 0px 0px 0.1rem`,
    borderColor: colors.base,
    transition: `all 250ms cubic-bezier(0.4,0,0.2,1) 0s`,
  },
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}


const ContactForm = (props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [showModal, setShowModal] = useState(false)

  const handleSubmit = e => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', name, email, subject, message }),
    })
      .then(handleSuccess)
      .catch(error => alert(error))

    e.preventDefault()
  }

  const handleSuccess = () => {
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')
    setShowModal(true)
  }

  return (
    <div>
      <form
        name="contact"
        onSubmit={handleSubmit}
        netlify-honeypot="bot-field"
        data-netlify="true"
        css={css({
          maxWidth: breakpoints.md,
          padding: `${space[4]}px`,
          backgroundColor: `${colors.lightGray}`,
          borderRadius: `4px`,
        })}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div
          css={css({
            [mediaQueries.lg]: {
              display: `grid`,
              gridTemplateColumns: `1fr 1fr`,
              gridGap: `${space[3]}px`,
            },
          })}
        >
          <div css={css(formGroupStyle)}>
            <label css={css(labelStyle)}>
              <span css={css(labelSpanStyle)}>Name*</span>
              <input
                type="text"
                name="name"
                id="name"
                css={css(inputStyle)}
                required
                onChange={e => {
                  setName(e.target.value)
                }}
              />
            </label>
          </div>
          <div css={css(formGroupStyle)}>
            <label css={css(labelStyle)}>
              <span css={css(labelSpanStyle)}>Email*</span>
              <input
                type="email"
                name="email"
                id="email"
                css={css(inputStyle)}
                required
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
            </label>
          </div>
        </div>
        <div css={css(formGroupStyle)}>
          <label css={css(labelStyle)}>
            <span css={css(labelSpanStyle)}>Subject</span>
            <input
              type="text"
              name="subject"
              id="subject"
              css={css(inputStyle)}
              onChange={e => {
                setSubject(e.target.value)
              }}
            />
          </label>
        </div>
        <div css={css(formGroupStyle)}>
          <label css={css(labelStyle)}>
            <span css={css(labelSpanStyle)}>Message*</span>
            <textarea
              name="message"
              id="message"
              type="text"
              placeholder="Tell us what you need"
              rows="5"
              css={css(inputStyle)}
              onChange={e => {
                setMessage(e.target.value)
              }}
              required
            />
          </label>
        </div>
        <input
          name="submit"
          type="submit"
          value="Submit"
          css={css({
            ...button.base,
            ...button.dark,
          })}
        />
      </form>
      <DialogOverlay
        isOpen={showModal}
        onDismiss={() => setShowModal(false)}
        css={css({
          zIndex: zIndices.submitFormModal,
          display: `flex`,
          justifyContent: `center`,
          alignItems: `center`,
          background: `${colors.base} !important`,
        })}
      >
        <DialogContent>
          <p
            css={css({
              marginBottom: `${space[4]}px`,
              fontSize: fontSizes[3],
            })}
          >
            Thanks for reaching out! We'll get back to you as soon as possibble.
          </p>
          <Button dark onClick={() => setShowModal(false)}>
            Got it
          </Button>
        </DialogContent>
      </DialogOverlay>
    </div>
  )
}

export default ContactForm